<template>
  <div class="h-screen-ios min-h-screen-ios">
    <header
      class="bg-surface-base h-20 lg:h-[90px] grid items-center fixed left-0 right-0 lg:border-b lg:border-surface-60"
    >
      <h1 class="flex justify-center overflow-hidden">
        <Img
          src="/images/heltia-insights-logo.png"
          alt="Heltia Logo"
          width="128"
          height="55"
          @click="$router.push('/')"
          class="cursor-pointer"
        />
      </h1>
    </header>
    <main id="app" class="min-h-screen w-screen bg-surface-base">
      <div class="pt-20">
        <SalusLoading
          v-if="!isAuthStoreInitialized"
          :isLoading="!isAuthStoreInitialized"
        />
        <NuxtPage v-else />
      </div>
    </main>
  </div>
</template>
<script setup lang="ts">
import { useAuthStore } from "~/store/auth";

const authStore = useAuthStore();

const isAuthStoreInitialized = computed(() => authStore.getIsInitialized);
</script>
